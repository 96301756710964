$brand-color: #ffcc00;
$font-title: "Inria Serif", serif;
$font-body: Inter, sans-serif;

* {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  font-family: $font-body;
  font-size: 15px;
}

body {
  background-color: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: rgb(94, 12, 107);
  text-decoration: none;
}

h1,
h2,
h3 {
  font-family: $font-title;
}

h1 {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 20px;
}

.button {
  background-color: $brand-color;
  border: none;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
  font-family: $font-body;
  font-size: 1rem;
  padding: 12px 24px;
}

.button--large {
  font-size: 1.2rem;
  padding: 18px 32px;
}

.page {
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  line-height: 60px;

  .desktop {
    a {
      color: #000;
      margin-left: 30px;
      text-decoration: none;
    }
  }

  .mobile {
    display: none;
  }

  .logo {
    align-items: center;
    display: flex;

    a {
      align-items: center;
      display: flex;

      img {
        width: 170px;
      }
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 0;
}

.hero {
  font-family: $font-title;
  text-align: center;
  padding: 100px 20px 50px 20px;

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
}

.state-info {
  border-radius: 20px;
  background: linear-gradient(
    0.45turn,
    rgba(94, 12, 107, 0.34),
    rgba(255, 199, 42, 0.3)
  );
  padding: 30px 20px 20px 20px;
  text-align: center;

  p {
    font-size: 1rem;
    margin-bottom: 30px;
    color: #555;
  }

  img {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: block;
  }
}

.map {
  align-items: start;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  .content {
    flex-basis: 380px;
    padding-top: 50px;

    h3 {
      font-size: 2rem;
      font-weight: bold;
      text-align: left;
    }

    ul {
      font-size: 1.3rem;
      line-height: 25px;
      padding: 0 20px 0 15px;
    }

    li {
      text-align: left;
    }
  }

  .image {
    max-width: 100%;
    padding: 15px 0;
    width: 600px;

    .usa-state.highlighted {
      fill: #333;
    }

    .usa-state.active {
      fill: $brand-color;
    }
  }
}

.features {
  padding: 20px 100px 40px 100px;
}

.feature {
  align-items: center;
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 20px 0;

  .image {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 300px;
    width: 300px;

    img {
      max-width: 100%;
    }

    video {
      width: 400px;
    }
  }

  .content {
    padding: 20px;

    p {
      margin-bottom: 15px;
    }

    .learn {
      text-align: left;
    }
  }
}

.testimonials {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 50px 50px 20px 50px;

  .testimonial {
    background-color: rgba(255, 255, 255, 0.36);
    border-radius: 15px;
    flex: 1;
    max-width: 300px;
    padding: 30px;
    text-align: center;

    p:first-child {
      font-style: italic;
      margin-bottom: 20px;
    }
  }
}

.testimonial-slider {
  background-color: #efe6f0;
  padding: 40px 20px;
  text-align: center;

  .testimonial {
    background: url(/assets/icons/quote.svg) 0 50px no-repeat;
    padding: 40px 50px;
    text-align: left;

    p {
      font-size: 1.2rem;
      padding: 10px 0;
    }
  }

  .navigation {
    bottom: 80px;
    position: relative;
    right: 80px;
    text-align: right;

    span {
      background: center center no-repeat;
      background-size: contain;
      cursor: pointer;
      display: inline-block;
      height: 30px;
      margin: 0 10px;
      width: 30px;

      &.left {
        background-image: url(/assets/icons/arrow-left.svg);
      }

      &.right {
        background-image: url(/assets/icons/arrow-right.svg);
      }
    }
  }
}

.person {
  align-items: center;
  display: flex;
  gap: 30px;
  padding: 20px;

  .image {
    background: #ccc center center no-repeat;
    background-size: cover;
    border-radius: 999px;
    flex-shrink: 0;
    height: 150px;
    width: 150px;
  }

  .content {
    h3 {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 8px;
      img {
        position: relative;
        top: -3px;
        vertical-align: middle;
        width: 24px;
      }
    }

    h4 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.partners {
  background-color: rgba(94, 12, 107, 0.1);
  padding: 30px 0;
  margin-bottom: 50px;
  text-align: center;

  h2 {
    margin-bottom: 30px;
  }

  img {
    filter: grayscale(1);
    max-height: 60px;
    max-width: 120px;
  }
}

.content-page {
  padding: 80px 0 60px 0;
}

.learn {
  padding: 30px 0 50px 0;
  text-align: center;
}

article {
  padding: 0;

  a {
    text-decoration: underline;

    &.button {
      text-decoration: none;
    }
  }

  p {
    font-size: 1.1rem;
    margin: 0 0 30px 0;
  }
}

.faq {
  margin-bottom: 50px;

  h2 {
    margin-top: 40px;
  }

  h3 {
    font-weight: bold;
    font-size: 1.8rem;
  }

  li {
    background: #f2f2f2;
    border-radius: 15px;
    list-style: none;
    margin-bottom: 15px;
    padding: 20px 20px 25px 20px;

    p {
      font-size: 1.1rem;
      margin-bottom: 0;
    }
  }
}

.faq-preview {
  padding: 0 100px 30px 100px;

  h2 {
    margin-bottom: 30px;
    text-align: center;
  }

  .accordion__item {
    border-bottom: 1px solid #eee;
    padding: 20px 15px;

    h3 {
      cursor: pointer;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .accordion__button {
    background: url(/assets/icons/plus.png) center right no-repeat;
    background-size: 12px;
    padding-right: 25px;
  }

  .accordion__panel {
    padding: 20px 0;

    p {
      margin-bottom: 0;
    }
  }
}

.about {
  .intro {
    margin-bottom: 40px;
    text-align: center;
  }

  .mission {
    background: rgba(255, 199, 42, 0.3);
    border-radius: 15px;
    font-family: $font-title;
    line-height: 2.5rem;
    margin: 0 0 40px 0;
    padding: 40px;
    text-align: center;

    p {
      font-size: 1.5rem;
      padding: 0 80px;
    }
  }

  .more {
    margin: 50px 0;
    text-align: center;
  }
}

.quote {
  background-color: rgba(94, 12, 107, 0.1);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;

  p {
    margin-bottom: 0;
  }
}

.title-section {
  background-color: #efe6f0;
  padding: 60px 0;
  text-align: center;
}

.gradient {
  background: rgb(255, 246, 224);
  background: linear-gradient(
    135deg,
    rgba(255, 246, 224, 1) 0%,
    rgba(232, 208, 232, 1) 100%
  );
}

.howitworks {
  padding: 50px 0;
  text-align: center;
}

.steps {
  display: flex;
  justify-content: center;
  padding: 50px 50px 20px;
  text-align: left;

  .step {
    padding: 12px 60px 50px 60px;
    position: relative;

    .number {
      background: #fff;
      border-radius: 999px;
      font-size: 1.6rem;
      font-family: $font-title;
      height: 48px;
      left: 0;
      line-height: 48px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 48px;
    }
  }
}

footer {
  background-color: #333;
  color: white;
  padding: 30px 20px 40px 20px;
  text-align: center;

  img {
    width: 150px;
  }

  p {
    margin-bottom: 20px;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  nav {
    a {
      color: $brand-color;
      margin: 0 10px;
      text-decoration: none;
    }
  }
}
