@media only screen and (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  header {
    border-bottom: 1px solid #ccc;
    margin: 0 -20px;
    padding: 0 20px;

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }

  footer {
    .company {
      display: none;
      margin: -5px 0 10px 0;

      span {
        display: none;
      }
    }
  }

  .hamburger {
    display: block;
  }

  .menu {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;

    li {
      border-bottom: 1px solid #eee;
      display: flex;

      a {
        color: #000;
        flex: 1;
        text-decoration: none;
        padding: 0 20px;
      }
    }
  }

  .hero {
    padding: 30px 20px 60px 20px;
  }

  .map {
    display: block;

    .content {
      padding-top: 15px;
    }
  }

  .features {
    padding-left: 0;
    padding-right: 0;
  }

  .feature {
    display: block;

    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .partners {
    padding-bottom: 0;
  }

  .testimonials {
    display: block;
    padding: 25px 20px 20px 20px;
  }

  .testimonial {
    margin-bottom: 20px;
    max-width: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .team {
    text-align: center;
  }

  .person {
    display: block;
    padding: 0;

    .image {
      display: inline-block;
      margin-bottom: 20px;
    }

    .content {
      text-align: center;
    }
  }

  .faq-preview {
    padding: 0 0 50px 0;
  }

  .about {
    .mission {
      padding: 40px 30px 30px 30px;

      p {
        padding: 0;
      }
    }
  }

  .steps {
    display: block;
  }
  .testimonial-slider {
    .navigation {
      bottom: 0;
      position: relative;
      right: 0;
      text-align: center;
    }
  }
}
